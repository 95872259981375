"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.$HomeBotSelectionCardName = exports.$HomeBotSelectionCardAvatarLoader = exports.$HomeBotSelectionCardAvatarImg = exports.$HomeBotSelectionCardAvatar = exports.$HomeBotSelectionCardButtonDesktop = exports.$HomeBotSelectionCardButtonMobile = exports.$HomeBotSelectionCard = exports.$HomeBotSelectionList = exports.$HomeBotSelectionListScrollWrapper = exports.$HomeBotSelectionHeaderModeLink = exports.$HomeBotSelectionHeaderModeDescription = exports.$HomeBotSelectionHeaderMode = exports.$HomeBotSelectionHeaderTitle = exports.$HomeBotSelectionHeader = exports.$HomeBotSelection = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const style_1 = require("../../general/Link/style");
const constants_1 = require("../../common/constants");
function getTheme(theme) {
    return {
        backgroundColor: theme.semantic_color.bg_primary.bg_page,
        title: {
            color: theme.semantic_color.text.primary,
            description: theme.semantic_color.text.secondary,
        },
        card: {
            name: theme.semantic_color.text.secondary,
        },
        sliderColors: {
            track: 'transparent',
            normal: theme.semantic_color.scrollbar.default,
            hover: theme.semantic_color.scrollbar.hover,
        },
        shadow: {
            backgroundColors: {
                left: `linear-gradient(90deg, ${theme.semantic_color.bg_primary.bg_page} 0%, rgba(0, 0, 0, 0) 100%)`,
                right: `linear-gradient(-90deg, ${theme.semantic_color.bg_primary.bg_page} 0%, rgba(0, 0, 0, 0) 100%)`,
            },
        },
    };
}
function getShadowLeftBackgroundColor(props) {
    if (props.$shadowLeft)
        return getTheme(props.theme).shadow.backgroundColors.left;
    return 'transparent';
}
function getShadowRightBackgroundColor(props) {
    if (props.$shadowRight)
        return getTheme(props.theme).shadow.backgroundColors.right;
    return 'transparent';
}
exports.$HomeBotSelection = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;
  padding: 20px 20px 14px;

  background-color: ${(props) => getTheme(props.theme).backgroundColor};
  border-radius: 4px;

  & > * {
    min-width: 0;
  }
`;
exports.$HomeBotSelectionHeader = styled_components_1.default.div `
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;

  @media (${constants_1.media.tablet}) {
    flex-direction: row;
    gap: 32px;
  }
`;
exports.$HomeBotSelectionHeaderTitle = styled_components_1.default.h2 `
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  box-sizing: border-box;
  font-family: ${constants_1.fonts.rightGroteskTight};
  font-weight: ${constants_1.fontWeights.medium};
  font-size: 56px;
  line-height: 50px;

  color: ${(props) => getTheme(props.theme).title.color};
  text-transform: uppercase;
  margin: 0;
  padding: 0;

  @media (${constants_1.media.tablet}) {
    font-size: 56px;
    line-height: 48px;
  }
`;
exports.$HomeBotSelectionHeaderMode = styled_components_1.default.div `
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  font-size: 14px;
  line-height: 20px;
  font-weight: ${constants_1.fontWeights.light};
  letter-spacing: -0.32px;

  @media (${constants_1.media.tablet}) {
    align-items: flex-end;
    gap: 8px;

    font-size: 16px;
    line-height: 16px;
    text-align: right;
  }
`;
exports.$HomeBotSelectionHeaderModeDescription = styled_components_1.default.p `
  margin: 0;
  padding: 0;
  color: ${(props) => getTheme(props.theme).title.description};
`;
exports.$HomeBotSelectionHeaderModeLink = styled_components_1.default.div ``;
exports.$HomeBotSelectionListScrollWrapper = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  /* container-name: scroll-wrapper;
  container-type: inline-size; */

  display: flex;
  transition: all 0.333s ease-in;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    width: 32px;
    opacity: 0;
    z-index: 1;
    user-select: none;
    pointer-events: none;
    transition: opacity 200ms ease-out;
  }

  &::before {
    left: 0;
    background: ${(props) => getShadowLeftBackgroundColor(props)};
    opacity: ${(props) => (props.$shadowLeft ? 1 : 0)};
  }

  &::after {
    right: 0;
    background: ${(props) => getShadowRightBackgroundColor(props)};
    opacity: ${(props) => (props.$shadowRight ? 1 : 0)};
  }

  @media (${constants_1.media.tablet}) {
    margin-left: -20px;
    margin-right: -20px;
  }
`;
exports.$HomeBotSelectionList = styled_components_1.default.div `
  /* display: flex;
  gap: 16px;
  flex-wrap: nowrap; */
  box-sizing: border-box;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px 24px;
  width: 100%;
  padding-bottom: 14px;

  /* @container scroll-wrapper (min-width: 1176px) { */

  @media (${constants_1.media.tablet}) {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    width: auto;
    gap: 16px;

    padding-left: 20px;
    padding-right: 20px;
    overflow-x: auto;
  }

  @media (${constants_1.media.smallDesktop}) {
    width: 100%;
  }

  &::-webkit-scrollbar {
    height: 3px;
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => getTheme(props.theme).sliderColors.track};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => getTheme(props.theme).sliderColors.normal};
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${(props) => getTheme(props.theme).sliderColors.hover};
  }
`;
exports.$HomeBotSelectionCard = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  & ${style_1.$StyledLink} {
    font-weight: ${constants_1.fontWeights.light};
  }

  @media (${constants_1.media.tablet}) {
    align-items: stretch;
    min-width: 112px;
  }

  ${({ $disabled }) => $disabled &&
    (0, styled_components_1.css) `
      pointer-events: none;
    `}
`;
exports.$HomeBotSelectionCardButtonMobile = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;

  @media (${constants_1.media.tablet}) {
    display: none;
  }
`;
exports.$HomeBotSelectionCardButtonDesktop = styled_components_1.default.div `
  position: relative;
  box-sizing: border-box;
  display: none;

  @media (${constants_1.media.tablet}) {
    display: block;
  }
`;
exports.$HomeBotSelectionCardAvatar = styled_components_1.default.div `
  /* width: 128px;
  height: 128px; */
  flex-grow: 1;

  /* min-width: 128px; */
  width: 100%;
  height: auto;
  pointer-events: none;
  user-select: none;

  @media (${constants_1.media.tablet}) {
    width: auto;
    min-width: 112px;
  }
`;
exports.$HomeBotSelectionCardAvatarImg = styled_components_1.default.img `
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
exports.$HomeBotSelectionCardAvatarLoader = styled_components_1.default.div `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
exports.$HomeBotSelectionCardName = styled_components_1.default.div `
  font-size: 14px;
  line-height: 16px;
  font-weight: ${constants_1.fontWeights.light};
  color: ${(props) => getTheme(props.theme).card.name};

  @media (${constants_1.media.tablet}) {
    font-size: 16px;
    line-height: 20px;
  }
`;
