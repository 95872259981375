"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeBotSelection = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const HomeBotSelectionBots_1 = require("./_internal/HomeBotSelectionBots");
const HomeBotSelectionHeader_1 = require("./_internal/HomeBotSelectionHeader");
const style_1 = require("./style");
exports.HomeBotSelection = (0, react_1.memo)(function HomeBotSelection({ header, bots, renderLink, renderImage, isLoading = false, botSelectedToPlay, }) {
    return ((0, jsx_runtime_1.jsxs)(style_1.$HomeBotSelection, { children: [(0, jsx_runtime_1.jsx)(HomeBotSelectionHeader_1.HomeBotSelectionHeader, { data: header, renderLink: renderLink }), (0, jsx_runtime_1.jsx)(HomeBotSelectionBots_1.HomeBotSelectionList, { bots: bots, renderImage: renderImage, isLoading: isLoading, botSelectedToPlay: botSelectedToPlay })] }));
});
