"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.$Skeleton = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const animation = (0, styled_components_1.keyframes) `from { opacity: 0.6; } 40% { opacity: 0.2} to { opacity: 0.6; }`;
function getTheme(theme) {
    return {
        primary: {
            backgroundColor: theme.semantic_color.bg_primary.bg_page,
            borderColor: theme.semantic_color.border.card,
        },
        secondary: {
            backgroundColor: theme.semantic_color.bg_primary.first_layer,
            borderColor: theme.semantic_color.border.card,
        },
    };
}
exports.$Skeleton = styled_components_1.default.div.attrs((props) => {
    var _a, _b, _c, _d, _e;
    return ({
        style: {
            height: (_a = props.$height) !== null && _a !== void 0 ? _a : undefined,
            minHeight: (_b = props.$minHeight) !== null && _b !== void 0 ? _b : undefined,
            width: (_c = props.$width) !== null && _c !== void 0 ? _c : '100%',
            minWidth: (_d = props.$minWidth) !== null && _d !== void 0 ? _d : undefined,
            borderRadius: (_e = props.$borderRadius) !== null && _e !== void 0 ? _e : undefined,
            flexShrink: props.$shrink ? 1 : 0,
        },
    });
}) `
  position: relative;
  box-sizing: border-box;
  display: flex;
  border-radius: 4px;
  background: ${(props) => getTheme(props.theme)[props.$type].backgroundColor};
  border: 1px solid ${(props) => getTheme(props.theme)[props.$type].borderColor};
  animation: 1.4s ease-in-out infinite ${animation};
  overflow-anchor: none;
`;
