import { routesConstants } from '@constants';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import { LobbyServerTags } from '@types';
import { useEffect } from 'react';
import { SocketStatus } from 'shared/types';

export const useLobbySubscriptionOnGameRequestQueueUpdate = () => {
  const {
    state: { ws },
    actions: { wsLobbyServerSubscribeTag, requestGamesInQueueData },
  } = useLobbyContext();

  useEffect(() => {
    requestGamesInQueueData();
  }, [requestGamesInQueueData]);

  useEffect(() => {
    if (ws.status === SocketStatus.CONNECTED) {
      wsLobbyServerSubscribeTag({
        name: LobbyServerTags.CHALLENGE_SHORT_UPDATED,
        pages: [routesConstants.LOBBY, routesConstants.HOME],
      });
    }
  }, [ws.status, wsLobbyServerSubscribeTag]);
};
