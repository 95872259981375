import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import { useCountdown } from 'shared/hooks/_useCountdown.hook';
import { BoardType, GameRatingMode } from 'shared/types';
import { msToTime } from 'shared/helpers/_game';
import { useApplicationContext } from '@application';
import { syncedMoment } from 'shared/helpers/_common';

const timeInMilliseconds = 30000;

const getTimerValue = (createdAt: string) => {
  const endTime = dayjs(createdAt).add(timeInMilliseconds);
  return endTime.diff(syncedMoment());
};

export const useGameSearchData = () => {
  const {
    state: { shortChallenges, requestExpired, clearShortChallengesRequest },
    actions: { clearShortChallenges },
  } = useLobbyContext();

  const { localization: l } = useApplicationContext();

  const humanBotChallenges = useMemo(
    () =>
      shortChallenges
        .filter(
          (item) =>
            [GameRatingMode.UNRATED, GameRatingMode.RATED].includes(
              item.rating_type as GameRatingMode
            ) &&
            ![BoardType.CLASSIC, BoardType.DAILY].includes(
              item.time_control.board_type
            )
        )
        .sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        ),
    [shortChallenges]
  );

  const fideChallenges = useMemo(
    () =>
      shortChallenges.filter(
        (item) =>
          GameRatingMode.FIDERATED === (item.rating_type as GameRatingMode)
      ),
    [shortChallenges]
  );

  const humanBotChallenge = useMemo(
    () => (humanBotChallenges.length ? humanBotChallenges[0] : null),
    [humanBotChallenges]
  );

  const { milliseconds, setMilliseconds, startTimer, stopTimer, updateTimer } =
    useCountdown({
      timeInMilliseconds,
      autostart: false,
    });

  useEffect(() => {
    if (humanBotChallenge) {
      const value = getTimerValue(humanBotChallenge.created_at);
      updateTimer(value);
      startTimer(value);
    } else {
      stopTimer();
      setMilliseconds(timeInMilliseconds);
    }
  }, [humanBotChallenge]);

  const humanBotSearchText = useMemo(() => {
    return `${l?.lobby?.start_your_game?.texts.if_no_human} ${msToTime(
      milliseconds
    )}`;
  }, [l, milliseconds]);

  if (
    !!humanBotChallenges.length &&
    !fideChallenges.length &&
    milliseconds <= 20000
  ) {
    return {
      text: humanBotSearchText || '',
      disabled: clearShortChallengesRequest,
      onCancel: clearShortChallenges,
    };
  }

  if (!shortChallenges.length && requestExpired)
    return {
      text: l?.lobby?.start_your_game?.texts.cant_find_opponent || '',
    };

  return undefined;
};
