import { HomeBotSelectionBot } from '@chessarena/components/lib/home/HomeBotSelection/types';
import { useLobbyContext } from '@store/context/lobby_context/_lobby.context';
import { useCallback, useMemo, useState } from 'react';
import { eChessColor } from 'shared/types';
import { defaultBots } from '../_constants';
import { GameSettings } from '../_types';
import { useBotSelectionSectionContext } from '../context';
import { RawBot } from '../context/_types';
import { useAlertPopup } from '@utils/hooks/_useAlertPopup.hook';

export const useBotSelectionBots = (botGameSettings: GameSettings) => {
  const {
    state: { data },
  } = useBotSelectionSectionContext();

  const {
    state: { inviteDataRequest },
    actions: { createInviteChallenge },
  } = useLobbyContext();

  const {
    isAlertPopupShown,
    alertPopupRef,
    onClose,

    alertText,
    showAlertPopup,
  } = useAlertPopup();

  const [selectedBotToPlay, setSelectedBotToPlay] = useState<number | null>(
    null
  );

  const createOnClickHandler = useCallback(
    (index: number, bot: RawBot) => async () => {
      const desiredColor =
        botGameSettings.playerSide !== 'random'
          ? (botGameSettings.playerSide as eChessColor)
          : undefined;

      setSelectedBotToPlay(index);

      await createInviteChallenge({
        params: {
          timeControlId: botGameSettings.timeControl.timeControlId,
          ratingFrom: bot.rating?.from,
          ratingTo: bot.rating?.to,
          desiredColor,
          inviteType: 'bot',
        },
        popups: {
          alert: showAlertPopup,
        },
      });

      setSelectedBotToPlay(null);
    },
    [
      botGameSettings.playerSide,
      botGameSettings.timeControl.timeControlId,
      createInviteChallenge,
      showAlertPopup,
    ]
  );

  const mergeDefaultBotsWithServer = useCallback(
    (bots?: RawBot[]) => {
      return defaultBots.map((defaultBot, index) => {
        const bot = bots?.[index];

        return {
          src: bot?.src || defaultBot.src,
          name: bot?.name ?? defaultBot.name,
          action: {
            title: bot?.action ?? defaultBot.action,
            onClick: createOnClickHandler(index, bot ?? defaultBot),
          },
        };
      });
    },
    [createOnClickHandler]
  );

  const bots = useMemo<HomeBotSelectionBot[]>(
    () => mergeDefaultBotsWithServer(data?.bots),
    [data?.bots, mergeDefaultBotsWithServer]
  );

  return {
    bots,
    selectedBotToPlay,
    isLoading: inviteDataRequest,
    botSelectionAlert: {
      isAlertPopupShown,
      alertPopupRef,
      onClose,
      text: alertText,
    },
  };
};
