import { Tab } from '@chessarena/components/lib/general/Tabs/types';
import { GameSettingsTab } from '@chessarena/components/lib/home/Home/types';
import ChesspiecePawn from '@chessarena/components/lib/chesspieces/ChesspiecePawn';
import ChesspiecePawnStroke from '@chessarena/components/lib/chesspieces/ChesspiecePawnStroke';

export const playerSideTabs: Tab<GameSettingsTab>[] = [
  {
    id: 1,
    value: 'white',
    label: 'White',
    icon: ChesspiecePawn,
  },
  {
    id: 2,
    value: 'black',
    label: 'Black',
    icon: ChesspiecePawnStroke,
  },
  {
    id: 3,
    value: 'random',
    label: 'Random',
  },
] as const;
