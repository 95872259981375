"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeGameSettingsPopup = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const useMediaBreakpoint_1 = require("../../utils/useMediaBreakpoint");
const DesktopPopup_1 = require("./_internal/DesktopPopup");
const MobilePopup_1 = require("./_internal/MobilePopup");
const PopupContent_1 = require("./_internal/PopupContent");
const useIsDesktop = () => {
    const breakpoint = (0, useMediaBreakpoint_1.useMediaBreakpoint)({
        init: (width) => width === 0,
        desktop: (width, { tablet }) => width >= tablet,
        mobile: (width, { tablet }) => width < tablet,
    });
    return breakpoint;
};
const BreakpointComponentMap = {
    init: null,
    desktop: DesktopPopup_1.DesktopPopup,
    mobile: MobilePopup_1.MobilePopup,
};
exports.HomeGameSettingsPopup = (0, react_1.memo)(function HomeGameSettingsPopup(_a) {
    var { onClose } = _a, props = __rest(_a, ["onClose"]);
    const breakpoint = useIsDesktop();
    const PopupComponent = BreakpointComponentMap[breakpoint];
    if (!PopupComponent)
        return null;
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(PopupComponent, { onClose: onClose, children: (0, jsx_runtime_1.jsx)(PopupContent_1.PopupContent, Object.assign({}, props)) }) }));
});
