import { InputOption } from '@chessarena/components/lib/inputs/types';
import { ILocalization } from 'shared/types';

export const getTimeControlTypes = (
  l: ILocalization,
  timeControlsOrder?: string[]
): InputOption[] => {
  if (!timeControlsOrder) return [];
  // const types = Object.keys(data.time_controls);

  return timeControlsOrder.map((item) => ({
    title: l.common.time_controls.full_names[item],
    value: item,
  }));
};
