import { createContext, useContext } from 'react';

import { IBotSelectionSectionContext } from './_types';

export const BotSelectionSectionContext = createContext(
  {} as IBotSelectionSectionContext
);

export const useBotSelectionSectionContext = () =>
  useContext(BotSelectionSectionContext);
