import { useState, useEffect, useRef } from 'react';
import { syncedDate } from '../helpers/_common';
/**
 * Хук таймера (обратного отсчёта). Принимает на вход:
 * @param {number} timeInMilliseconds - время в миллисекундах.
 * @param {boolean} autostart - нужно ли выполнять автозапуск таймера при инициализации.
 * @param {number} tickTime - время обновления значения таймера.
 * @param {Function} callback - коллбэк-функция, которая выполнится после дохождения до 0.
 *
 * @returns {number} milliseconds - время до конца таймера (в мс)
 * @returns {Function} setMilliseconds - фунция для установки времени таймера
 * @returns {Function} stopTimer - фунция для остановки таймера
 * @returns {Function} startTimer - фунция для старта таймера
 * @returns {Function} updateTimer - фунция для обновления времени таймера
 * @returns {boolean} paused - приостановлен ли таймер
 */
export function useCountdown({ timeInMilliseconds, autostart = true, tickTime = 1000, callback, }) {
    const intervalRef = useRef(null);
    const [milliseconds, setMs] = useState(Math.max(timeInMilliseconds, 0));
    const [paused, setPaused] = useState(true);
    function setMilliseconds(milliseconds) {
        setMs(Math.max(milliseconds, 0));
    }
    function stopTimer() {
        if (intervalRef.current) {
            setPaused(true);
            setPaused(true);
            window.clearInterval(intervalRef.current);
            intervalRef.current = null;
            setPaused(true);
        }
    }
    function countDown(endTime) {
        setMs(() => {
            let decrement = endTime - syncedDate().getTime();
            if (decrement <= 0) {
                decrement = 0;
                stopTimer();
                if (callback) {
                    callback({ setMilliseconds: setMs, startTimer, resetTimer });
                }
                setPaused(true);
            }
            return decrement;
        });
    }
    function startTimer(startMs) {
        const ms = startMs ? startMs : milliseconds;
        if (ms && !intervalRef.current) {
            setPaused(false);
            setMilliseconds(ms);
            setPaused(false);
            setMilliseconds(ms);
            const endTime = syncedDate().getTime() + ms;
            setMilliseconds(endTime - syncedDate().getTime());
            intervalRef.current = window.setInterval(() => countDown(endTime), tickTime);
            setPaused(false);
        }
    }
    function resetTimer() {
        stopTimer();
        setMilliseconds(0);
    }
    function updateTimer(ms) {
        if (paused) {
            setMilliseconds(ms);
        }
        else {
            stopTimer();
            setMilliseconds(ms);
            setMilliseconds(ms);
            const endTime = syncedDate().getTime() + ms;
            setMilliseconds(endTime - syncedDate().getTime());
            intervalRef.current = window.setInterval(() => countDown(endTime), tickTime);
            setPaused(false);
        }
    }
    useEffect(() => {
        return function cleanup() {
            resetTimer();
        };
    }, []);
    useEffect(() => {
        if (intervalRef.current === null && autostart) {
            startTimer();
        }
    }, [milliseconds]);
    return {
        milliseconds,
        setMilliseconds,
        stopTimer,
        startTimer,
        updateTimer,
        paused,
    };
}
