"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollContainerSides = void 0;
const react_1 = require("react");
/**
 * Хук, который определяет есть ли с двух сторон скролл у контейнера
 */
const useScrollContainerSides = (...deps) => {
    const [hasLeftSideScroll, setHasLeftSideScroll] = (0, react_1.useState)(false);
    const [hasRightSideScroll, setHasRightSideScroll] = (0, react_1.useState)(false);
    const containerNodeRef = (0, react_1.useRef)(null);
    const onScroll = (0, react_1.useCallback)(() => {
        const container = containerNodeRef.current;
        if (!container)
            return;
        if (container.scrollLeft > 0) {
            setHasLeftSideScroll(true);
        }
        else {
            setHasLeftSideScroll(false);
        }
        if (container.scrollLeft + container.clientWidth + 1 < container.scrollWidth) {
            setHasRightSideScroll(true);
        }
        else {
            setHasRightSideScroll(false);
        }
    }, [containerNodeRef]);
    (0, react_1.useEffect)(() => {
        const container = containerNodeRef.current;
        if (container) {
            onScroll();
            container.addEventListener('scroll', onScroll);
            window.addEventListener('resize', onScroll);
            return () => {
                container.removeEventListener('scroll', onScroll);
                window.removeEventListener('resize', onScroll);
            };
        }
    }, [containerNodeRef, onScroll, ...deps]); // eslint-disable-line
    return { containerNodeRef, hasLeftSideScroll, hasRightSideScroll };
};
exports.useScrollContainerSides = useScrollContainerSides;
