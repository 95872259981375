import ChesspiecePawn from '@chessarena/components/lib/chesspieces/ChesspiecePawn';
import ChesspiecePawnStroke from '@chessarena/components/lib/chesspieces/ChesspiecePawnStroke';
import { Tab } from '@chessarena/components/lib/general/Tabs/types';
import { GameSettingsTab } from '@chessarena/components/lib/home/HomeGameSettingsPopup/types';
import { RawBot } from './context/_types';

export const STORAGE_KEYS = {
  BOT_SETTINGS_TIME_CONTROL: 'bot-settings-time-control',
  BOT_SETTINGS_PLAYER_SIDE: 'bot-settings-player-side',
} as const;

export const gameSettingsTabs: Tab<GameSettingsTab>[] = [
  {
    id: 1,
    value: 'white',
    label: 'White',
    icon: ChesspiecePawn,
  },
  {
    id: 2,
    value: 'black',
    label: 'Black',
    icon: ChesspiecePawnStroke,
  },
  {
    id: 3,
    value: 'random',
    label: 'Random',
  },
] as const;

export const defaultBots: RawBot[] = [
  {
    src: 'https://gaming-images.worldchess.com/media/ai-robots/bot-ai-1.svg',
    name: 'Level 1',
    action: '0—400',
    rating: { from: 0, to: 400 },
  },
  {
    src: 'https://gaming-images.worldchess.com/media/ai-robots/bot-ai-2.svg',
    name: 'Level 2',
    action: '400—1200',
    rating: { from: 400, to: 1200 },
  },
  {
    src: 'https://gaming-images.worldchess.com/media/ai-robots/bot-ai-3.svg',
    name: 'Level 3',
    action: '1200—1400',
    rating: { from: 1200, to: 1400 },
  },
  {
    src: 'https://gaming-images.worldchess.com/media/ai-robots/bot-ai-4.svg',
    name: 'Level 4',
    action: '1400—1700',
    rating: { from: 1400, to: 1700 },
  },
  {
    src: 'https://gaming-images.worldchess.com/media/ai-robots/bot-ai-5.svg',
    name: 'Level 5',
    action: '1700—2000',
    rating: { from: 1700, to: 2000 },
  },
  {
    src: 'https://gaming-images.worldchess.com/media/ai-robots/bot-ai-6.svg',
    name: 'Level 6',
    action: '2000—2300',
    rating: { from: 2000, to: 2300 },
  },
  {
    src: 'https://gaming-images.worldchess.com/media/ai-robots/bot-ai-7.svg',
    name: 'Level 7',
    action: '2300—2700',
    rating: { from: 2300, to: 2700 },
  },
  {
    src: 'https://gaming-images.worldchess.com/media/ai-robots/bot-ai-8.svg',
    name: 'Level 8',
    action: '2700+',
    rating: { from: 2700 },
  },
];
