import { GameSettingsTab } from '@chessarena/components/lib/home/HomeGameSettingsPopup/types';
import { InputEventParams } from '@chessarena/components/lib/inputs/types';
import { THomeTimeControls } from '@pages/home/_types';
import { useApplicationContext } from '@application';
import { getLSItem, setLSItem } from '@utils/_local_storage';
import { getTimeControlTitle } from '@utils/timeControls/_getTimeControlTitle';
import { useCallback, useMemo, useState } from 'react';
import { BoardType } from 'shared/types';
import { gameSettingsTabs, STORAGE_KEYS } from '../_constants';
import { GameSettings } from '../_types';
import { BotSelectionData } from '../context/_types';
import { useGameSettingsTimeControlsData } from './useGameSettingsTimeControlsData.hook';

const getTimeControlById = (
  timeControls: THomeTimeControls | undefined,
  type: string,
  id: string
) => {
  return timeControls?.[type as unknown as BoardType].find(
    (item) => item.id === +id
  );
};

export const useBotSelectionSettingsPopupData = (
  data: BotSelectionData | undefined
) => {
  const { localization: l } = useApplicationContext();
  const [isSettingsPopupOpened, setIsSettingsPopupOpened] = useState(false);

  const [playerSide, setPlayerSide] = useState<GameSettingsTab>(
    getLSItem(STORAGE_KEYS.BOT_SETTINGS_PLAYER_SIDE) ?? 'white'
  );

  const {
    timeControls,
    typesOptions,
    timesOptions,
    timeControlType,
    timeControlTime,
    onTimeControlTypeChange,
    onTimeControlTimeChange,
    resetTimeControls,
  } = useGameSettingsTimeControlsData();

  const [botGameSettings, setBotGameSettings] = useState<GameSettings>({
    playerSide,
    timeControl: {
      typeText: timeControlType,
      timeText: getTimeControlTitle(
        getTimeControlById(timeControls, timeControlType, timeControlTime),
        l
      ),
      timeControlId: Number(timeControlTime),
    },
  });

  const openSettingsPopup = useCallback(() => {
    setIsSettingsPopupOpened(true);
  }, []);

  const closeSettingsPopup = useCallback(() => {
    setIsSettingsPopupOpened(false);
  }, []);

  const onPlayerSideClick = useCallback((tab: GameSettingsTab) => {
    setPlayerSide(tab);
  }, []);

  const onTimeControlTypeClick = useCallback(
    (typeParams: InputEventParams) => {
      onTimeControlTypeChange(String(typeParams.value));
    },
    [onTimeControlTypeChange]
  );

  const onTimeControlTimeClick = useCallback(
    ({ value }: InputEventParams) => {
      onTimeControlTimeChange(value as string);
    },
    [onTimeControlTimeChange]
  );

  const onSaveClick = useCallback(() => {
    const timeControl = getTimeControlById(
      timeControls,
      timeControlType,
      timeControlTime
    );

    setBotGameSettings({
      playerSide,
      timeControl: {
        typeText: timeControlType,
        timeText: getTimeControlTitle(timeControl, l),
        timeControlId: timeControl?.id ?? null,
      },
    });

    setLSItem(STORAGE_KEYS.BOT_SETTINGS_TIME_CONTROL, timeControl);
    setLSItem(STORAGE_KEYS.BOT_SETTINGS_PLAYER_SIDE, playerSide);

    closeSettingsPopup();
  }, [
    closeSettingsPopup,
    l,
    playerSide,
    timeControlTime,
    timeControlType,
    timeControls,
  ]);

  const buttonProps = useMemo(
    () => ({
      title: data?.settingsPopup?.button ?? '',
      onClick: onSaveClick,
    }),
    [data?.settingsPopup?.button, onSaveClick]
  );

  const resetSettings = useCallback(() => {
    setPlayerSide(botGameSettings.playerSide);

    resetTimeControls();
  }, [botGameSettings.playerSide, resetTimeControls]);

  const onPopupClose = useCallback(() => {
    closeSettingsPopup();
    resetSettings();
  }, [closeSettingsPopup, resetSettings]);

  const settingsPopup = useMemo(() => {
    return {
      isOpened: isSettingsPopupOpened,
      closeSettingsPopup,
      openSettingsPopup,
      title: data?.settingsPopup?.title ?? '',
      description: data?.settingsPopup?.description ?? '',
      buttonProps,
      onClose: onPopupClose,
      playerSide,
      playerSideTabs: gameSettingsTabs,
      timeControlTypes: typesOptions,
      timeControlTimes: timesOptions,
      timeControlType: timeControlType,
      onTimeControlTypeClick,
      timeControlTime: timeControlTime,
      onTimeControlTimeClick,
      onPlayerSideClick,
    };
  }, [
    isSettingsPopupOpened,
    closeSettingsPopup,
    openSettingsPopup,
    data?.settingsPopup?.title,
    data?.settingsPopup?.description,
    buttonProps,
    onPopupClose,
    playerSide,
    typesOptions,
    timesOptions,
    timeControlType,
    onTimeControlTypeClick,
    timeControlTime,
    onTimeControlTimeClick,
    onPlayerSideClick,
  ]);

  return { botGameSettings, settingsPopup, openSettingsPopup };
};
