import { InputOption } from '@chessarena/components/lib/inputs/types';
import { THomeTimeControls } from '@pages/home/_types';

import { BoardType, ILocalization } from 'shared/types';
import { getTimeControlTitle } from './_getTimeControlTitle';

export const getTimeControlTimes = (
  l: ILocalization,
  timeControls?: THomeTimeControls,
  type?: string
): InputOption[] => {
  if (!timeControls || !type) return [];
  const times = timeControls[type as unknown as BoardType];
  return times.map((item) => ({
    title: getTimeControlTitle(item, l),
    value: item.id.toString(),
  }));
};
