export enum ePaywallPopupStep {
  INFO = 'info',
  PAYMENT_SETTINGS = 'payment-settings',
}

export enum ePaywallPopupType {
  PRICING = 'pricing',
  REGISTRATION = 'registration',
  PUZZLES = 'puzzles',
  TOURNAMENTS = 'tournaments',
  GAMES = 'games',
  MASTERCLASS = 'masterclass',
}

export enum ePaywallPopupPaymentMethod {
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
}
