import { TBotSelectionStoredDataResponse } from '../_types';
import { BotSelectionData } from './_types';

export const adaptBotSelectionResponse = (
  data?: TBotSelectionStoredDataResponse
): BotSelectionData | undefined => {
  if (!data) return;

  return {
    header: {
      title: data.header.title ?? '',
    },
    bots: data.bots,
    settingsPopup: {
      title: data.settings_popup?.title ?? 'AI Game Settings',
      description:
        data.settings_popup?.description ??
        'Set up your game to play with a bot. These setting will be applied to all our bots.',
      button: data.settings_popup?.button ?? 'Save AI game settings',
    },
  };
};
