import { RenderImageParams } from '@chessarena/components/lib/utils/renderImageTypes';
import Image from 'next/image';
import { useBotSelectionBots } from './useBotSelectionBots.hook';
import { useBotSelectionHeaderData } from './useBotSelectionHeaderData.hook';

export const useBotSelectionData = () => {
  const { header, settingsPopup, botGameSettings } =
    useBotSelectionHeaderData();

  const { bots, selectedBotToPlay, isLoading, botSelectionAlert } =
    useBotSelectionBots(botGameSettings);

  return {
    header,
    bots,
    // renderLink: ({ link, children }: RenderLinkParams) => {
    //   return <Link href={link}>{children}</Link>;
    // },
    renderImage: ({ src, alt, style }: RenderImageParams) => (
      <Image
        src={src}
        width="128"
        height="128"
        alt={alt ?? ''}
        style={{ width: '100%', height: '100%', ...style }}
      />
    ),
    settingsPopup,
    botSelectedToPlay: selectedBotToPlay,
    isLoading,
    botSelectionAlert,
  };
};
