"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeControlTime = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const style_1 = require("./style");
// Взято из HomeTimeControl. Стоит сделать более общий компонент из ДС
exports.TimeControlTime = (0, react_1.memo)(function TimeControlTime({ time }) {
    const [first, second] = time;
    return ((0, jsx_runtime_1.jsxs)(style_1.$TimeControlTime, { "data-component": "TimeControlTime", "$withIncrement": second === null || second === void 0 ? void 0 : second.increment, children: [(0, jsx_runtime_1.jsxs)(style_1.$TimeControlTimeItemFirst, { children: [(0, jsx_runtime_1.jsx)(style_1.$TimeControlTimeValue, { children: first.value }), first.title && !(second === null || second === void 0 ? void 0 : second.increment) && ((0, jsx_runtime_1.jsx)(style_1.$TimeControlTimeTitle, { children: first.title }))] }), second && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [second.increment && ((0, jsx_runtime_1.jsxs)(style_1.$TimeControlTimeItemFirst, { children: [(0, jsx_runtime_1.jsx)(style_1.$TimeControlTimeValue, { children: "+" }), first.title && ((0, jsx_runtime_1.jsx)(style_1.$TimeControlTimeTitleCentered, { children: first.title }))] })), (0, jsx_runtime_1.jsxs)(style_1.$TimeControlTimeItemSecond, { children: [(0, jsx_runtime_1.jsx)(style_1.$TimeControlTimeValue, { children: second.value }), second.title && (0, jsx_runtime_1.jsx)(style_1.$TimeControlTimeTitle, { children: second.title })] })] }))] }));
});
