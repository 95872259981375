import { HomeBotSelection } from '@chessarena/components/lib/home/HomeBotSelection';
import { HomeGameSettingsPopup } from '@chessarena/components/lib/home/HomeGameSettingsPopup';
import { FC, memo } from 'react';
import { useBotSelectionData } from '../hooks/useBotSelectionData.hook';
import { AlertPopup } from '@components/molecules/popups/_AlertPopup';

export const BotSelectionFragment: FC = memo(function BotSelectionFragment() {
  const { settingsPopup, botSelectionAlert, ...botSelectionData } =
    useBotSelectionData();

  const { isOpened, ...settingsPopupProps } = settingsPopup;

  return (
    <>
      <HomeBotSelection {...botSelectionData} />
      {isOpened && <HomeGameSettingsPopup {...settingsPopupProps} />}
      {botSelectionAlert.isAlertPopupShown && (
        <AlertPopup {...botSelectionAlert} />
      )}
    </>
  );
});
