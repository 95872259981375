import { FC, PropsWithChildren, useMemo, useState } from 'react';

import { TBotSelectionStoredDataResponse } from '../_types';
import { adaptBotSelectionResponse } from './_adapters';
import { BotSelectionSectionContext } from './_context';
import {
  IBotSelectionContextState,
  IBotSelectionSectionContext,
} from './_types';
import { IBotSelectionSectionProps } from '../BotSelectionSection';
import { THomeTimeControls } from '@pages/home/_types';

const initialState: IBotSelectionContextState = {
  data: undefined,
  timeControls: undefined,
  gameSettingsTimeControlOrder: undefined,
};

const createInitialState = (
  data?: TBotSelectionStoredDataResponse,
  timeControls?: THomeTimeControls,
  gameSettingsTimeControlOrder?: string[]
): IBotSelectionContextState => {
  const botSelectionData = adaptBotSelectionResponse(data);
  return {
    ...initialState,
    data: botSelectionData,
    timeControls,
    gameSettingsTimeControlOrder:
      gameSettingsTimeControlOrder ?? Object.keys(timeControls ?? {}),
  };
};

export const BotSelectionSectionContextProvider: FC<
  PropsWithChildren<IBotSelectionSectionProps>
> = ({ children, data, timeControls, gameSettingsTimeControlOrder }) => {
  const [state] = useState(
    createInitialState(data, timeControls, gameSettingsTimeControlOrder)
  );

  const value = useMemo<IBotSelectionSectionContext>(
    () => ({
      state,
    }),
    [state]
  );

  return (
    <BotSelectionSectionContext.Provider value={value}>
      {children}
    </BotSelectionSectionContext.Provider>
  );
};
