import { FC, memo } from 'react';
import { BotSelectionSectionContextProvider } from './context';
import { BotSelectionFragment } from './fragments/BotSelectionFragment';
import { TBotSelectionStoredDataResponse } from './_types';
import { THomeTimeControls } from '@pages/home/_types';

export interface IBotSelectionSectionProps {
  data?: TBotSelectionStoredDataResponse;
  timeControls?: THomeTimeControls;
  gameSettingsTimeControlOrder?: string[];
}

export const BotSelectionSection: FC<IBotSelectionSectionProps> = memo(
  function BotSelectionSection(props) {
    return (
      <BotSelectionSectionContextProvider {...props}>
        <BotSelectionFragment />
      </BotSelectionSectionContextProvider>
    );
  }
);
